/* eslint-disable @typescript-eslint/typedef */
import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { MainLayoutComponent } from './layout/pages/main-layout/main-layout.component';
import { AuthNotLoggedUserGuard } from './auth/guards/auth-not-logged-user/auth-not-logged-user.guard';
import { AuthLoggedUserGuard } from './auth/guards/auth-logged-user/auth-logged-user.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((module) => module.AuthModule),
    canActivate: [(): Observable<boolean | UrlTree> => inject(AuthNotLoggedUserGuard).canActivate()],
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'workshops',
        loadChildren: () => import('./workshops/workshops.module').then((module) => module.WorkshopsModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then((module) => module.UsersModule),
      },
      {
        path: 'news',
        loadChildren: () => import('./news/news.module').then((module) => module.NewsModule),
      },
      {
        path: 'changelog',
        loadChildren: () => import('./changelog/changelog.module').then((module) => module.ChangelogModule),
      },
      {
        path: 'role-management',
        loadChildren: () => import('./role-management/role-management.module').then((module) => module.RoleManagementModule),
      },
      {
        path: 'material-consumption-report',
        loadChildren: () =>
          import('./material-consumption-report/material-consumption-report.module').then(
            (module) => module.MaterialConsumptionReportModule
          ),
      },
      {
        path: 'repair-report',
        loadChildren: () => import('./repair-report/repair-report.module').then((module) => module.RepairReportModule),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/workshops',
      },
    ],
    canActivate: [(): Observable<boolean | UrlTree> => inject(AuthLoggedUserGuard).canActivate()],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
