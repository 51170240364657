import { forkJoin, lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CurrentLanguageService } from '../../../i18n/services/current-language/current-language.service';
import { AuthUserService } from '../../../auth/services/auth-user/auth-user.service';

export const AppInitializerFactory = (
  currentLanguageService: CurrentLanguageService,
  authUserService: AuthUserService
): (() => Promise<void>) => {
  const initData$: Observable<void> = forkJoin([currentLanguageService.initCurrentLanguage(), authUserService.initCurrentUser()]).pipe(
    map(() => void 0),
    catchError(() => of(void 0))
  );

  return (): Promise<void> => lastValueFrom(initData$);
};
