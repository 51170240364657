import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { LanguageEnum } from '../../enums/language/language.enum';

@Injectable({
  providedIn: 'root',
})
export class CurrentLanguageService {
  private currentLanguage$: BehaviorSubject<string>;
  private readonly defaultLanguage: string;

  constructor(private translateService: TranslateService) {
    this.defaultLanguage = LanguageEnum.pl;
    this.currentLanguage$ = new BehaviorSubject<string>(this.defaultLanguage);
    this.translateService.setDefaultLang(this.defaultLanguage);
  }

  public initCurrentLanguage(): Observable<void> {
    return of(void 0).pipe(
      tap(() => {
        this.setCurrentLanguage(this.defaultLanguage);
      }),
      map(() => void 0)
    );
  }

  public getCurrentLanguageAsObservable(): Observable<string> {
    return this.currentLanguage$.asObservable();
  }

  public getCurrentLanguage(): string {
    return this.currentLanguage$.value;
  }

  private setCurrentLanguage(language: string): void {
    this.translateService.use(language);
    this.currentLanguage$.next(language);
  }
}
