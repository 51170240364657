import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CurrentUserService } from '../../../users/services/current-user/current-user.service';
import { CurrentUserModel } from '../../../users/models/current-user/current-user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthLoggedUserGuard {
  private readonly loginPageUrl: string;

  constructor(private router: Router, private currentUserService: CurrentUserService) {
    this.loginPageUrl = '/auth/login';
  }

  public canActivate(): Observable<UrlTree | boolean> {
    return this.isUserLogged();
  }

  private isUserLogged(): Observable<UrlTree | boolean> {
    return this.currentUserService.getCurrentUser().pipe(
      take(1),
      map((currentUser: CurrentUserModel | null) => {
        if (currentUser) {
          return true;
        }

        return this.router.createUrlTree([this.loginPageUrl]);
      })
    );
  }
}
