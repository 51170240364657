import { ApiErrorDto } from '../../dtos/api-error/api-error.dto';
import { ApiErrorModel } from '../../models/api-error/api-error.model';
import { ApiFormFieldErrorDto } from '../../dtos/api-form-field-error/api-form-field-error.dto';
import { ApiFormFieldErrorCreator } from '../api-form-field-error/api-form-field-error.creator';
import { ApiFormFieldErrorModel } from '../../models/api-form-field-error/api-form-field-error.model';

export class ApiErrorCreator {
  public static create(dto: ApiErrorDto, errorStatus: number): ApiErrorModel {
    return {
      status: errorStatus,
      type: dto.type,
      message: dto.message,
      formFieldErrors: ApiErrorCreator.createFormFieldErrors(dto.errors),
    } as ApiErrorModel;
  }

  private static createFormFieldErrors(formFieldErrorsDto: Array<ApiFormFieldErrorDto>): Array<ApiFormFieldErrorModel> {
    return formFieldErrorsDto.map((formFieldErrorDto: ApiFormFieldErrorDto) => ApiFormFieldErrorCreator.create(formFieldErrorDto));
  }
}
