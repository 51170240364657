import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localePl from '@angular/common/locales/pl';
import localePlExtra from '@angular/common/locales/extra/pl';

import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { TranslateLoaderFactory } from './i18n/factories/translate/translate.factory';
import { LottiePlayerFactory } from './shared/factories/lottie-player/lottie-player.factory';
import { CurrentLanguageService } from './i18n/services/current-language/current-language.service';
import { AppInitializerFactory } from './core/factories/app-initializer/app-initializer.factory';
import { AuthUserService } from './auth/services/auth-user/auth-user.service';
import { AuthTokenService } from './auth/services/auth-token/auth-token.service';
import { AuthAccessTokenHeaderInterceptor } from './auth/interceptors/auth-access-token-header/auth-access-token-header.interceptor';
import { ApiErrorInterceptor } from './api-errors/interceptors/api-error/api-error.interceptor';
import { FormControlDatepickerDateFormatterService } from './forms/services/form-control-datepicker-date-formatter/form-control-datepicker-date-formatter.service';

registerLocaleData(localePl, 'pl', localePlExtra);

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    NgbModule,
    LayoutModule,
    LottieComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthAccessTokenHeaderInterceptor,
      multi: true,
      deps: [AuthTokenService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorInterceptor,
      multi: true,
      deps: [Injector, Router, AuthUserService],
    },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [CurrentLanguageService, AuthUserService],
      multi: true,
    },
    { provide: NgbDateParserFormatter, useClass: FormControlDatepickerDateFormatterService },
    provideLottieOptions({
      player: LottiePlayerFactory,
    }),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
