import { ApiFormFieldErrorDto } from '../../dtos/api-form-field-error/api-form-field-error.dto';
import { ApiFormFieldErrorModel } from '../../models/api-form-field-error/api-form-field-error.model';

export class ApiFormFieldErrorCreator {
  public static create(dto: ApiFormFieldErrorDto): ApiFormFieldErrorModel {
    return {
      fieldName: dto.field,
      message: dto.message,
    } as ApiFormFieldErrorModel;
  }
}
